// src/App.js

import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MovieListingPage from './MovieListingPage';
import BottomNav from './components/BottomNav';
import MovieDetail from './MovieDetail';
import WatchLaterPage from './pages/WatchLaterPage';
import SearchPage from './pages/SearchPage';
import { FilterProvider } from './FilterContext';
import './layout.css';
import SettingsPage from './pages/SettingsPage';


const App = () => {
  const [cachedMovies, setCachedMovies] = useState([]);
  const [lastFilters, setLastFilters] = useState(null);

  return (
    <Router>
      <FilterProvider>
        <div className="container">
          <Routes>
            <Route 
              path="/" 
              element={
                <MovieListingPage 
                  cachedMovies={cachedMovies} 
                  setCachedMovies={setCachedMovies}
                  lastFilters={lastFilters}
                  setLastFilters={setLastFilters}
                />
              } 
            />
            <Route path="/movie/:tmdbId" element={<MovieDetail />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/later" element={<WatchLaterPage />} />
            <Route path="/settings" element={<SettingsPage />} />
          </Routes>
          <BottomNav />
        </div>
      </FilterProvider>
    </Router>
  );
};

export default App;