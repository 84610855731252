// src/FilterContext.js

import React, { createContext, useState, useContext } from 'react';

const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [filters, setFilters] = useState(() => {
    const savedFilters = sessionStorage.getItem('movieFilters');
    return savedFilters ? JSON.parse(savedFilters) : { actors: [], director: [], writer: [], year: [] };
  });

  return (
    <FilterContext.Provider value={{ filters, setFilters }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilters = () => useContext(FilterContext);