// src/index.js

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import { PostHogProvider} from 'posthog-js/react'

const options = {
  api_host: "https://us.i.posthog.com"
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <PostHogProvider 
      apiKey="phc_Pkqne60xk24boPZoVPv5BK9VKSLxwTT0S46BQY2Pha8"
      options={options}
    >
      <App />
    </PostHogProvider>
  </React.StrictMode>
);