// src/Filters.js

import React from 'react';
import Select from 'react-select';
import { ACTORS, DIRECTORS, WRITERS } from './constants';
import './Filters.css';
import { useFilters } from './FilterContext';
import posthog from 'posthog-js';

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: '#121212', // Dark background for the input control
    color: '#fff',           // Text color
    borderColor: 'grey',
    fontSize: '16px'
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#333', // Dark background for the dropdown menu
  }),
  input: (provided) => ({
    ...provided,
    color: 'white',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#555' : '#333', // Highlight the option when focused
    color: '#fff',  // Text color
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'white',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#555',
  }),
};

const Filters = () => {
  const { filters, setFilters } = useFilters();

  const handleActorChange = (selectedOptions) => {
    let values = selectedOptions.map(option => option.value)
    if(values.length > 0) {
      posthog.capture('apply_filter', {"filter": "actor", "values": values});
    }
    const newFilters = {
      ...filters,
      actors: selectedOptions ? values : []
    };
    setFilters(newFilters);
  };

  const handleDirectorChange = (selectedOptions) => {
    let values = selectedOptions.map(option => option.value)
    if(values.length > 0) {
      posthog.capture('apply_filter', {"filter": "director", "values": values});
    }
    const newFilters = {
      ...filters,
      director: selectedOptions ? values : []
    };
    setFilters(newFilters);
  };

  const handleWriterChange = (selectedOptions) => {
    let values = selectedOptions.map(option => option.value)
    if(values.length > 0) {
      posthog.capture('apply_filter', {"filter": "writer", "values": values});
    }
    const newFilters = {
      ...filters,
      writer: selectedOptions ? values : []
    };
    setFilters(newFilters);
  };

  const handleYearChange = (selectedOptions) => {
    let newFilters;
    if (selectedOptions.length === 0) {
      newFilters = {
        ...filters,
        year: []
      };
    }
    else {
      const lastOption = selectedOptions[selectedOptions.length - 1];
      newFilters = {
        ...filters,
        year: [lastOption.value]
      };
    }
    if(newFilters.year.length > 0) {
      posthog.capture('apply_filter', {"filter": "year", "values": newFilters.year});
    }
    setFilters(newFilters);
  };

  return (
    <div className="filters">
      <div className="select-container">
        <Select styles={customStyles}
          isMulti
          name="actors"
          options={ACTORS.map(actor => ({ value: actor, label: actor }))}
          value={filters.actors.map(actor => ({ value: actor, label: actor }))}
          onChange={handleActorChange}
          placeholder="Select actors..."
        />
      </div>
      
      <div className="select-container">
        <Select styles={customStyles}
          isMulti
          name="director"
          options={DIRECTORS.map(director => ({ value: director, label: director }))}
          value={filters.director.map(director => ({ value: director, label: director }))}
          onChange={handleDirectorChange}
          placeholder="Select director..."
        />
      </div>

      <div className="select-container">
        <Select styles={customStyles}
          isMulti
          name="writer"
          options={WRITERS.map(writer => ({ value: writer, label: writer }))}
          value={filters.writer.map(writer => ({ value: writer, label: writer }))}
          onChange={handleWriterChange}
          placeholder="Select writer..."
        />
      </div>

      <div className="select-container">
        <Select styles={customStyles}
          isMulti
          name="year"
          options={[
            { value: "Before 1980", label: "Before 1980" },
            { value: "1980-1990", label: "1980-1990" },
            { value: "1991-2000", label: "1991-2000" },
            { value: "2001-2010", label: "2001-2010" },
            { value: "After 2010", label: "After 2010" }
          ]}
          value={filters.year.map(year => ({ value: year, label: year }))}
          onChange={handleYearChange}
          placeholder="Select year..."
        />
      </div>
      
      {/* <button onClick={clearFilters} className="clear-button">Clear Filters</button> */}
    </div>
  );
};

export default Filters;