import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Switch from 'react-switch';
import { IoArrowBackOutline } from "react-icons/io5";
import './SettingsPage.css';

const SettingsPage = () => {
  const navigate = useNavigate();
  const [showDubbed, setShowDubbed] = useState(() => {
    return localStorage.getItem('showDubbed') === 'true';
  });
  
  const [showPlot, setShowPlot] = useState(() => {
    return localStorage.getItem('showPlot') === 'true';
  });

  const handleBack = () => {
    navigate(-1);
  };

  const handleDubbedToggle = (checked) => {
    setShowDubbed(checked);
    localStorage.setItem('showDubbed', checked);
  };

  const handlePlotToggle = (checked) => {
    setShowPlot(checked);
    localStorage.setItem('showPlot', checked);
  };

  return (
    <div className="settings-page">
      <div className="settings-header">
        <span onClick={handleBack} className="back-button">
          <IoArrowBackOutline />
        </span>
        <h1>Settings</h1>
      </div>
      
      <div className="settings-section">
        <div className="setting-item">
          <div className="setting-info">
            <h3>Show Dubbed Movies</h3>
            <p>Include dubbed movies in search results</p>
          </div>
          <Switch
            onChange={handleDubbedToggle}
            checked={showDubbed}
            onColor="#bb86fc"
            offColor="#666"
            uncheckedIcon={false}
            checkedIcon={false}
            height={24}
            width={48}
            handleDiameter={20}
          />
        </div>

        <div className="setting-item">
          <div className="setting-info">
            <h3>Show Plot</h3>
            <p>Display movie plot in movie cards</p>
          </div>
          <Switch
            onChange={handlePlotToggle}
            checked={showPlot}
            onColor="#bb86fc"
            offColor="#666"
            uncheckedIcon={false}
            checkedIcon={false}
            height={24}
            width={48}
            handleDiameter={20}
          />
        </div>
      </div>

      <div className="settings-footer">
        <a 
          href="/privacy-policy" 
          target="_blank" 
          rel="noopener noreferrer"
          className="privacy-link"
        >
          Privacy Policy
        </a>
      </div>
    </div>
  );
};

export default SettingsPage;